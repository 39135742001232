import { Button } from "@mui/material";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

const Intro = () => {

	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const { id } = useParams();

	const formLink = () => {
    navigate("/first-form/" + id + "?" + searchParams, { replace: true });
  }

	return (
		<div className="intro-container container">
			<div className="intro">
				<div className="intro-heading">
					<h1>When you see <span>engagement opportunities</span> in the upcoming questions, consider how</h1>
				</div>
				<div className="intro-para">
					<p className="para-one">Your church engages with your community through small groups, outreach efforts, meals, sports, personal care, food drives, special events and more.</p>
					<p className="para-two">All of it counts.</p>
				</div>
				<div className="cta">
					<Button className="cta-btn" onClick={() => formLink()}>GOT IT!</Button>
				</div>
			</div>
		</div>
	)
}

export default Intro;
