export const sectionsText = {
  foundation: [
    "Have intentional conversations with your leadership team to determine and align on a vision statement.",
    "Hire an auditor/consultant from outside your team to evaluate your strategy and bring possible risks to your attention.",
  ],
  technology: [
    "Survey your team to know if there are any great ideas that are yet to move into development and production.",
    "Regularly attend tech conferences to keep up with the latest trends that could help improve your product.",
  ],
  product: [
    "Engage a team to regularly set and review goals for both long and short-term growth.",
    "Let your prospective customers get a free taste of your product and ask them to reveal crucial problems; address these concerns to get them on board.",
  ],
  process: [
    "Put together a team of your leaders and an auditor to regularly assess and update your processes and strategy.",
    "Collaborate with consultants outside your company to assess your product and development processes.",
  ],
  people: [
    "Survey your team to know how well they understand their responsibilities and the team's goals, and have intentional conversation to ensure that everyone is in alignment.",
    "Regularly reinforce the importance and validity of your vision through demonstrations of the future impact you are working toward and celebrations of current breakthroughs.",
  ],
  sameScores: [
    "Have intentional conversations with your leadership team to determine and align on a vision statement.",
    "Regularly attend tech conferences to keep up with the latest trends that could help improve your product.",
    "Let your prospective customers get a free taste of your product and ask them to reveal crucial problems; address these concerns to get them on board.",
    "Collaborate with consultants outside your company to assess your product and development processes.",
    "Survey your team to know how well they understand their responsibilities and the team's goals, and have intentional conversation to ensure that everyone is in alignment.",
  ],
};
