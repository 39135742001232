import { createContext, useEffect, useState } from 'react';

const QuestionsProgressContext = createContext();

export function QuestionsProgressContextProvider(props) {

  const [currentPage, setCurrentPage] = useState(650);
  const [currentQ, setCurrentQ] = useState(0);


  function updateCurrentPageHandler(page) {
    setCurrentPage(page);
  }

  const context = {
    currentPage: currentPage,
    currentQ: currentQ,
    updateCurrentPage: updateCurrentPageHandler,
  };

  return <QuestionsProgressContext.Provider value={context}>
    {props.children}
  </QuestionsProgressContext.Provider>
}

export default QuestionsProgressContext;
