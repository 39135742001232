import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useWindowDimensions from "../Hooks/useWindowDimensions";
import { useState, useEffect } from "react";

const DonutChart = (props) => {
  const { windowWidth } = useWindowDimensions();

  let fontSize = windowWidth > 645 ? 14 : 12;
  let fontWeight = windowWidth > 645 ? 700 : 600;
  let size = windowWidth > 645 ? 80 : 50;
  // const[isLoading, setIsLoading] = useState(false);

  console.log("DonutChart", props);
  const newData = props.data.filter((val) => val.name !== props.answer.name );
  
  
  // useEffect(() => {
  //   if(props.answer.length){
  //     setIsLoading(true);
  //   }
  // },[isLoading, props.answer.length])

  const answerData = [{
    name: props.answer.name, y: props.answer.y, selected: true, size: "180%", color: {
      radialGradient: {
        cx: 0.5,
        cy: 0.5,
        r: 0.5
      },
      stops: [
        [0, '#ED2028'],
        [1, '#8C4E50'],
      ]
    }
    , dataLabels: {
      color: "#CB3137",
      style: { fontWeight: "normal", fontSize: `${fontSize}px`, },
      distance: windowWidth < 830 ? 35 : 40,
    }
  }];

  const finalData = [...newData, ...answerData];
  // console.log("FINALDATA", finalData);


  const options = {
    chart: {
      type: "pie",
      spacing: windowWidth < 830 ? [15, 15, 15, 15] : [50, 50, 50, 50],
      events: {
        load: function () {
          //  if(isLoading){
             const graphic = this.series[0].data[finalData.length - 1].graphic;
             console.log(graphic);
             const prevR = graphic.r;
             graphic.attr({
               r: prevR + size + 20
             });

          //  }
        }

      }

    },
    title: { text: '' },

    tooltip: {
      // pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      // pointFormat: '<b>{point.percentage:.1f}%</b>',
      enabled: false, // disable onHover tooltip box 
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        size: `${size}%`,
        innerSize: "40%",
        depth: 30,
        // When setting the border width to 0, there may be small gaps between the slices due to SVG antialiasing 
        // artefacts. To work around this, keep the border width at 0.5 or 1, but set the borderColor to null instead.
        borderWidth: 1,
        borderRadius: 0,
        borderColor: null,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          distance: windowWidth < 830 ? 20 : 40,
          enabled: true,
          useHTML: true,
          formatter: function() {
            let label = this.point.name;
            let labelName = this.point.name.split(' ');
            let newLabelName = "";
            if(windowWidth < 830 && label?.length > 10 && labelName?.length >= 2) newLabelName = labelName?.join('<br/>');
            else newLabelName = this.point.name;
            return '<div style="display:flex; flex-direction:column; justify-content: center; text-align: center; font-family: Barlow, sans-serif;">' + "<p class='label-name' style=''>" + newLabelName + "</p><p class='label-percent' style=''>" + Math.round(this.point.percentage) + "%</p></div>";
          },
          // format: '<b>{point.name}</b> {point.percentage:.1f} %',
          connectorColor: 'none',
          style: {
            fontSize: `${fontSize}px`,
            fontWeight: `${fontWeight}`,
            lineHeight: "16.8px",
            color: "#6D6262"
          }
        }
      }
    },
    credits: { enabled: false },

    colors: ['#E6E6E6', '#A69797', '#6D6262', '#CECCCC'],
    series: [{
      data: finalData
      // data: [
      //   { name: "Neutral", y: 25, color: "#A69797" },
      //   { name: "Agree", y: 25, color: "#6D6262" },
      //   {
      //     name: props.answer.name, y: 25, selected: true, size: "180%", color: {
      //       radialGradient: {
      //         cx: 0.5,
      //         cy: 0.5,
      //         r: 0.5
      //       },
      //       stops: [
      //         [0, '#ED2028'],
      //         [1, '#8C4E50'],
      //       ]
      //     }
      //     , dataLabels: {
      //       color: "#CB3137",
      //       style: { fontWeight: "normal", fontSize: `${fontSize}px`, },
      //       distance: 80
      //     }
      //   },
      //   { name: "Strongly Disagree", y: 10, color: "#E6E6E6" },
      //   { name: "Disagree", y: 15, color: "#CECCCC" },


      //   //   20,20,20,20,20
      // ],

    }]
  };
  return (
    // <div id="chart-container" style={{ width: "100%", height: "342px" }}>
    <div id="chart-container" style={{ width: "100%", height: "auto" }}>
      <HighchartsReact highcharts={Highcharts} options={options}></HighchartsReact>
    </div>
  )
}

export default DonutChart;