import { useState, useRef, useEffect, useMemo } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import VideoThumbnail from '../../Assets/Images/video-thumbnail.png';
// import VideoThumbnailMobile from '../Assets/Images/video-thumbnail-mobile.png';
// import PlayButton from '../Assets/Images/play-button.png';
import playBtn from '../../Assets/Images/play-btn.png';
import playBtnHover from '../../Assets/Images/play-btn-hover.png';
import Clock from '../../Assets/Images/clock.png';
import { Close, Pause, PlayArrow } from "@mui/icons-material";
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import '../../App.scss';
import './StartV2.scss';
import { Button } from '@mui/material';


const StartV2 = props => {

  const { windowWidth } = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [isVidPaused, setIsVidPaused] = useState(false);
  // const [startPlaying, setStartPlaying]   = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [playBtnImg, setPlayBtnImg] = useState(playBtn);

  const vidRef = useRef(null);
  const introElmRef = useRef(null);
  const videoElmRef = useRef(null);

  const display = searchParams.get("display");
  const navigate = useNavigate();



  // useEffect(() => {
  //   if(display === "preview") {
  //     console.log("changed background img for embedded preview display...");
  //     document.body.style.background = `#242424 url(${introBgImg}) repeat`;
  //   } else {
  //     document.body.style.background = `#414042`;
  //   }
  // }, [display]);

  const createAssessment = () => {

    // TODO: change the pageId according to what the page ID is for this assessment
    const pageId = 686;

    let leaderId = 0;
    let userId = 0;
    let isMember = false;
    if (searchParams?.get("leader")) {
      leaderId = searchParams?.get("leader");
      userId = searchParams?.get("user");
      isMember = true;
    }

    const clientId = process.env.REACT_APP_CLIENT_ID;
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ clientId, isMember, leaderId, userId })
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        // navigate("/intro/" + data.id + "?" + searchParams, { replace: true });
        navigate("/assessment/" + data.id + "/" + pageId + "?" + searchParams, { replace: true });
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to create new assessment...", err));
  }


  const howItWorksLink = () => {
    if (searchParams == "") {
      return "/how-it-works";
    } else {
      return "/how-it-works?" + searchParams;
    }
  }
  // console.log(searchParams.get("display"));

  const assessmentLink = () => {
    return "/assessment?" + searchParams;
  }

  const preAssessmentQuestionsLink = () => {
    navigate("/pre-additional-questions?" + searchParams);
  }

  const introPageLink = () => {
    createAssessment();
    // navigate("/intro/" + id + "?" + searchParams);
  }

  const playVideo = (e) => {

    if (windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;
      const video = vidRef.current;
      introElm.style.cssText = "width: 0%; padding: 0; overflow: hidden;"
      videoElm.style.cssText = "width: 100%; border-radius: 10px";
      video.style.cssText = "width: 100%; object-fit: cover;";
      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }
    vidRef.current.play();
    setIsPlayingVideo(true);
    setIsVidPaused(false);
    // if(!startPlaying) {
    //   setStartPlaying(true);
    // }
  }

  const pauseVideo = (e) => {
    // vidRef.current.pause();
    // setPlayingVideo(false);
    vidRef.current.pause();
    setIsVidPaused(true);
    setPlayBtnImg(playBtn);

    if (windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;
      const video = vidRef.current;

      introElm.style.cssText = "width: 50%; padding: 0 2% 0 3%;"
      videoElm.style.cssText = "width: 50%;";
      video.style.cssText = "height: 100%; width: auto;";
      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }
    setIsPlayingVideo(false);
  }

  const handlePlayPause = () => {
    if (isPlayingVideo) {
      pauseVideo();
    } else {
      playVideo();
    }
    setIsPlayingVideo(!isPlayingVideo);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(vidRef.current.currentTime);
  };

  const handleSeek = (e) => {
    const newTime = e.target.value;
    vidRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleVideoPlaybackEnd = () => {
    setIsPlayingVideo(false);
    setIsVidPaused(false);
    setPlayBtnImg(playBtn);

    if (windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;

      introElm.style.cssText = "";
      videoElm.style.cssText = "";

      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }
    // showThumbnail();
    showPlayButton();
  }

  const showThumbnail = useMemo(() => {

    if (!isPlayingVideo && !isVidPaused) {
      return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} style={{ opacity: '1' }}
      // onClick={(e) => pauseVideo(e)} 
      />;
    } else {
      // return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} style={{ opacity: '0' }} onClick={(e) => pauseVideo(e)} />;
      return null;
      // if(startPlaying) {
      //   return null
      // } else {
      //   return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} />
      // }
    }
    // }, [isPlayingVideo, startPlaying]);
  }, [isPlayingVideo, isVidPaused]);

  const showPlayButton = () => {
    if (isPlayingVideo) {
      return null
    } else {
      return <img alt="play button" className="playbtn" src={playBtnImg} onClick={(e) => playVideo(e)} onMouseEnter={() => setPlayBtnImg(playBtnHover)} onMouseLeave={() => setPlayBtnImg(playBtn)} />
    }
  }

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    if (!seconds) return "0:00";
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }


  // const vidsrcURL = "https://40parables-assets.s3.amazonaws.com/bleat-Parable-Dollar-on-Mission-Assessment-Intro.mp4";
  const vidsrcURL = "https://40parables-assets.s3.amazonaws.com/bleat-Parable-Dollar-on-Mission-Assessment-Intro-v2.mp4";

  return (
    <div>
      <div className="container start">
        {/* <div className="video" ref={videoElmRef}>
        <video ref={vidRef} controls={false}
          onEnded={handleVideoPlaybackEnd}
          onPlay={() => setIsPlayingVideo(true)}
          onPause={() => {
            setIsPlayingVideo(false);
            setIsVidPaused(true);
            showPlayButton();
          }}
          onTimeUpdate={handleTimeUpdate}
        >

          <source src={vidsrcURL} type="video/mp4" />
        </video>
        custom video controls
        {isPlayingVideo && <div className="custom-controls">
          <button onClick={handlePlayPause}>
            {isPlayingVideo ? <Pause /> : <PlayArrow />}
          </button>
          <input className="custom-slider"
            type="range" min="0" max={vidRef.current ? vidRef.current.duration : 0}
            value={currentTime} onChange={handleSeek}
          />
          <span>
            {formatTime(Math.floor(currentTime))} /{' '}
            {formatTime(Math.floor(vidRef.current ? vidRef.current.duration : 0))}
          </span>
        </div>}
        // custom video controls
        // {showThumbnail()}
        {showThumbnail} // using useMemo 
        {showPlayButton()}

        {isPlayingVideo && <Close className="close-video" style={{
          position: "absolute", height: "20px", width: "20px", color: "#fefefede", opacity: 0.85, zIndex: 99, padding: "15px", top: "5px", cursor: "pointer",
          right: windowWidth > 1100 ? "15px" : "5px"
        }} onClick={(e) => pauseVideo(e)} />}

      </div> */}

        {windowWidth <= 830 && <div style={{ backgroundColor: "#00252A", height: "15px" }}></div>}
        <div className="intro" ref={introElmRef}>
          <h1>Do your church’s finances reflect its mission?</h1>

          {searchParams.get("leader") ?
            <>
              <p className="intro-para">Take this quick assessment.</p>
              <p className="intro-para line-two">Get a <span>snapshot of your financial leadership, </span>easy-to-use tips, and next steps!</p>
            </> :
            <>
              <p className="intro-para">Take this quick assessment, then invite your leaders to take it, too.</p>
              <p className="intro-para line-two">Get a <span>snapshot of your financial leadership, </span>easy-to-use tips, and next steps!</p>
            </>
          }
          {/* <div className="completion">See how you compare* with similarly-sized American churches</div> */}
          {/* <ol type="1" className="intro-points-list">
          {searchParams.get("leader") ? <>
            <li>1. Take this 3-minute assessment</li>
            <li>2. View your results instantly</li>
            <li>3. Evaluate if you are derailing or on track</li>
            <li>4. Evaluate if you are derailing or on track</li>
            <li>&nbsp;</li>
          </> : <>
            <li>1. Take this 3-minute assessment</li>
            <li>2. Get your leadership team to take it after you</li>
            <li>3. View everyone's results on 1 dashboard</li>
            <li>4. Evaluate if you are derailing or on track</li>
          </>}
        </ol> */}

          <div className="completion" style={searchParams.get("leader") && windowWidth > 830 ? { marginTop: '47px' } : null}>
            {/* <div className='btn-container'> */}
            {/* <Link to={assessmentLink()} className="cta">Get Started</Link> */}
            {/* <Button className="cta" onClick={() => introPageLink()}>FIND OUT</Button> */}
            <Button className="cta" onClick={() => introPageLink()}>TEST AND SEE</Button>
            {/* </div> */}
            <div className='time-estimate'>
              <div className='img-container'><img className='clock-icon' src={Clock} alt="estimated time" /></div><span>{searchParams.get("leader") ? "3 min" : "3 min"}</span>
            </div>
          </div>


          <div className='para-container'>
            <p className='para'>P.S. Leave your financial statements at home; you won't need them for this.</p>
          </div>
        </div>

      </div>
    </div>
  );
}

export default StartV2;
