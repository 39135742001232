import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, IndexRoute } from "react-router-dom";
import TagManager from 'react-gtm-module';

import CircularProgress from '@mui/material/CircularProgress';

import Header from './Components/Layout/Header';
import Footer from './Components/Layout/Footer';
import LeftLogo from './Components/Layout/LeftLogo';
import Start from './Pages/Start';
import Assessment from './Pages/Assessment';
import AssessmentV3 from './Pages/AssessmentV3';
import AssessmentAdditional from './Pages/AssessmentAdditional';
import Details from './Pages/Details';
import Results from './Pages/Results';
import Dashboard from './Pages/Dashboard';
import Invite from './Pages/Invite';
import InviteThankYou from './Pages/InviteThankYou';
import PreAdditionalInfo from './Pages/PreAdditionalInfo';
import PreAdditionalQuestions from './Pages/PreAdditionalQuestions';
// import PreAssessment from './Pages/PreAssessment';
// import { AssessmentProgressContextProvider } from './Store/AssessmentProgressContext';
// import AssessmentProgressSections from './Components/AssessmentProgressSections';

import { PreAdditionalQuestionContextProvider } from './Store/PreAdditionalQuestionContext';
import { AdditionalQuestionContextProvider } from './Store/AdditionalQuestionContext';
import { AssessmentQuestionContextProvider } from './Store/AssessmentQuestionContext';
import { QuestionsProgressContextProvider } from './Store/QuestionsProgressContext';
import DonutChart from './Components/DonutChart';
import Intro from './Pages/Intro';
import FirstForm from './Pages/FirstForm';
import Charts from './Pages/Chart.jsx';
import AssessmentAdditionalV3 from './Pages/AssessmentAdditionalV3';
import StartV2 from './Pages/StartV2/StartV2.jsx';

// ReactGA.initialize('');
// ReactGA.pageview(window.location.pathname + window.location.search);

const tagManagerArgs = {
  gtmId: 'GTM-N72ZB33F'
}

TagManager.initialize(tagManagerArgs)

function App() {

  const [isLoading, setIsLoading]         = useState(false);
  const [name, setName]                   = useState('');
  
  useEffect(() => {
    getClient();
  }, []);

  const getClient = () => {
    setIsLoading(false);
  }

  if (isLoading) {
		return <div className="container details loading"><CircularProgress style={{ color: '#EA1C22' }} size={60} /></div>
	}

  return (
    <div className="App">
      {/* <AssessmentProgressContextProvider> */}
          <BrowserRouter>
          <AssessmentQuestionContextProvider>
          <AdditionalQuestionContextProvider>
          <PreAdditionalQuestionContextProvider>
          <QuestionsProgressContextProvider>
            <Header />
            <main  className="main">
            {/* <LeftLogo/> */}
              {/* <AssessmentProgressSections /> */}
              <Routes>
                {/* <Route path="/" exact index element={<Start setName={setName} />} /> */}
                {/* <Route path="/start" exact element={<Start setName={setName} />}></Route> */}
                <Route path="/" exact index element={<StartV2 setName={setName} />} />
                <Route path="/start" exact element={<StartV2 setName={setName} />}></Route>
                {/* <Route path="/before-you-start" exact element={<PreAssessment />}></Route> */}
                {/* <Route path="/assessment" exact element={<Assessment />}></Route> */}
                <Route path="/assessment/:id/:pageId" exact element={<AssessmentV3 />}></Route>
                {/* <Route path="/assessment/:id/review" exact element={<Assessment />}></Route> */}
                {/* <Route path="/assessment/:id/:pageId/review" exact element={<AssessmentV3 />}></Route> */}
                <Route path="/pre-additional-info/:id" exact element={<PreAdditionalInfo />}></Route>
                <Route path="/pre-additional-questions/" exact element={<PreAdditionalQuestions />}></Route>
                {/* <Route path="/pre-additional-questions/:id" exact element={<PreAdditionalQuestions />}></Route> */}
                {/* <Route path="/assessment-additional/:id" exact element={<AssessmentAdditional />}></Route> */}
                {/* <Route path="/assessment-additional/:id/:pageId" exact element={<AssessmentAdditional />}></Route> */}
                <Route path="/assessment-additional/:id/:pageId" exact element={<AssessmentAdditionalV3 />}></Route>
                <Route path="/details/:id" exact element={<Details />}></Route>
                <Route path="/results/:id" exact element={<Results />}></Route>
                <Route path="/invite-team/:id" exact element={<Invite />}></Route>
                <Route path="/invite-team-thanks/:id" exact element={<InviteThankYou />}></Route>
                <Route path="/dashboard/:id" exact element={<Dashboard/>}></Route>
                <Route path="/chart/:id/:pageId" exact element={<DonutChart/>}></Route>
                {/* <Route path="/chart" exact element={<DonutChart/>}></Route> */}
                <Route path="/intro/:id" exact element={<Intro/>}></Route>
                <Route path="/intro" exact element={<Intro/>}></Route>
                <Route path="/first-form/:id" exact element={<FirstForm/>}></Route>
                <Route path="/first-form" exact element={<FirstForm/>}></Route>
                <Route path="/charts/:id/:pageId" exact element={<Charts/>}></Route>
                
              </Routes>
            </main>
            <Footer />
          </QuestionsProgressContextProvider>
          </PreAdditionalQuestionContextProvider>
          </AdditionalQuestionContextProvider>
          </AssessmentQuestionContextProvider>
          </BrowserRouter>
      {/* </AssessmentProgressContextProvider> */}
    </div>
  );
}

export default App;
